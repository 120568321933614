<template>
  <div>
    <v-row v-if="showAllItems">
      <v-col v-for="item in items" :key="item.id" cols="12" sm="4" lg="2">
        <Card
          :title="item.title"
          :img="item.thumbnail"
          :author="item.author"
          :reads="item.reads"
          :likes="item.likes"
          :userLike="item.user_like"
          :userRead="isUserRead(item)"
          :idBook="item.id"
          :wishlist="item.wishlist"
          :userWishlist="item.user_wishlist"
          :genre="item.genre"
          @getData="getDataItems()"
        />
      </v-col>
      <v-col
        v-if="items.length !== totalItems"
        cols="12"
        class="my-10 d-flex justify-center"
      >
        <v-btn :loading="loading" text color="primary" small @click="viewMore">
          {{ $t("homepage.view_more") }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-else>
      <ListCard
        v-for="(item, index) in itemsGenre"
        :key="item.id"
        :title="item.name"
        :idGenre="item.value"
        :class="index !== 0 && 'mt-10'"
        @clickViewAll="viewAllItems(item.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAllItems: Boolean,
    loading: Boolean,
    items: Array,
    itemsGenre: Array,
    totalItems: Number
  },
  components: {
    ListCard: () => import("./ListCard.vue"),
    Card: () => import("./Card.vue")
  },
  created() {
    const { name } = this.$route;
    this.isEbook = name === "Ebook";
  },
  data() {
    return {
      isEbook: false
    };
  },
  methods: {
    isUserRead(item) {
      return this.isEbook ? item.user_read : item.user_view;
    },
    getDataItems() {
      this.$emit("getItems");
    },
    viewMore() {
      this.$emit("getMoreItems");
    },
    viewAllItems(value) {
      this.$emit("viewAll", value);
    }
  }
};
</script>
